@if (signatureData$ | async; as signatureData) {
  <div>
    <p class="text-center">
      Bitte unterschreiben Sie im nachfolgenden Unterschriftenfeld, damit Ihr Antrag
      zur {{ getInsuranceTranslation(signatureData.insurance.insuranceType) }}-Versicherung gültig ist.
      <br />

      Ihren Antrag können Sie <a (click)="downloadDocument(signatureData.insurance.id)">hier</a> nochmals herunterladen.
    </p>

    <app-signature-pad
      (signingFinished)="signatureBase64 = $event"></app-signature-pad>

    <div class="signature-infos">
      <span>{{ signatureData.insurance.person.address.city }}, am {{ currentDate | date:'dd.MM.yyyy' }}</span>

      <button
        [disabled]="isLoading"
        (click)="submit(signatureData.insurance.id)"
        class="btn btn-sm btn-success">

        @if (isLoading) {
          <span
            class="spinner-grow spinner-grow-sm me-2"
            aria-hidden="true"></span>
          <span role="status">Antrag wird unterzeichnet</span>
        } @else {
          <span>Antrag unterzeichnen</span>
        }
      </button>
    </div>
  </div>
}

@if (showError) {
  <div class="alert alert-danger text-center">
    Dieser Antrag wurde bereits signiert. <br /> Sie können diese Seite schließen.
  </div>

}
